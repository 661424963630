




import Vue from 'vue';
import SetPassword from '@/components/login/SetPassword.vue';

export default Vue.extend({
  name: 'RegistrationForm.vue',
  components: {
    SetPassword,
  },
});
