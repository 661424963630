var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.checkSuccessful)?_c('v-container',{attrs:{"fill-height":""}},[_c('v-col',{staticClass:"pa-12 flex",attrs:{"justify":"center","align":"center"}},[_c('h2',[_vm._v(_vm._s(_vm.$t('set-password.title')))]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"password-form",attrs:{"action":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.setPassword)}}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":_vm.$t('set-password.name'),"outlined":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":_vm.$t('set-password.surname'),"outlined":""},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:8|password:@confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"type":"password","label":_vm.$t('set-password.password'),"outlined":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"confirm","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"type":"password","label":_vm.$t('set-password.password-confirmation'),"outlined":""},model:{value:(_vm.confirmation),callback:function ($$v) {_vm.confirmation=$$v},expression:"confirmation"}})]}}],null,true)}),_c('v-btn',{staticClass:"mx-auto d-block white--text",attrs:{"id":"change-password-button","color":"primary","type":"submit","disabled":invalid}},[_vm._v(" Set Password ")])],1)]}}],null,false,2910854391)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }