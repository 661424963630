



























































import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import RepositoryFactory from '@/services/RepositoryFactory';
import IInvitationRepository from '@/services/Repositories/Invitations/types';

const factory = new RepositoryFactory();

export default Vue.extend({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      name: '',
      surname: '',
      password: '',
      confirmation: '',
      code: this.$route.params.invitationCode,
      checkSuccessful: false,
    };
  },
  computed: {
    invitationRepository(): IInvitationRepository {
      return factory.get('invitations') as IInvitationRepository;
    },
  },
  methods: {
    async setPassword() {
      await this.invitationRepository.createUser(this.code, {
        password: this.password,
        firstName: this.name,
        lastName: this.surname,
      });
      await this.$router.replace({ path: '/login' });
    },
    async checkInvitation() {
      // eslint-disable-next-line max-len
      const data = await this.invitationRepository.getInvitationCode(this.$route.params.invitationCode);
      if (data.status === 200) {
        this.checkSuccessful = true;
      }
    },
  },
  created() {
    this.checkInvitation();
  },
});
